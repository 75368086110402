import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik, Form as FormikForm } from "formik";
import { CheckboxInput, SelectInput } from "../../../global/form";
import TemplateStringInputField from "../../../global/form/pos-integrations/TemplateStringInputField";

// STYLE
import "./ToastIntegrationModal.scss";
import { generalErrorAlert } from "../../../../util/Utils";
import ReactSelect from "react-select";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";

class ToastIntegrationModal extends Component {
  state = {
    toastSettings: {},
    restaurants: [],
    selectedRestaurant: null,
    deleteMenuItems: false,
    ticketFormat: "name_location",
    selectedEmployeeGuid: "",
    selectedRevenueCenterGuid: "",
    employees: [],
    revenueCenters: [],
    ticketFormats: [],
    toastPromos: [],
    toastCheckLevelPromos: [],
    hasLoaded: false,
    loadingData: false,
    savingData: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // when opening the modal for the first time OR for the first time after switching a customer
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getToastOptions();
    }
  }

  hideModal = async () => {
    const { onHide } = this.props;
    this.setState({ deleteMenuItems: false });
    await onHide();
  };

  getToastOptions = async () => {
    try {
      this.setState({ loadingData: true });
      const res = await axios.get("/admin/getAllToastConfigOptions");
      this.setState({
        toastSettings: res.data.toast_settings,
        restaurants: res.data.all_restaurants,
        selectedRestaurant: res.data.restaurant_for_customer,
        ticketFormat: res.data.ticket_format_for_customer.name,
        employees: res.data.toast_employees,
        revenueCenters: res.data.toast_revenue_centers,
        ticketFormats: res.data.ticket_formats,
        selectedEmployeeGuid: res.data.employee_for_customer?.guid,
        selectedRevenueCenterGuid: res.data.revenue_center_for_customer?.guid,
        showDesiredTimeOnLineItems: res.data.show_desired_time_on_line_items,
        tabNameTemplateString: res.data.tab_name_template_string,
        syncInventoryQuantities: res.data.sync_inventory_quantities,
        enableCloudTabs: res.data.enable_cloud_tabs,
        enableServerInitiatedTabs: res.data.enable_server_initiated_tabs,
        enableNonBbotSurcharge: res.data.enable_non_bbot_surcharge,
        enableReclaimHostLink: res.data.enable_reclaim_host_link,
        inject_guest_names: res.data.inject_guest_names,
        inject_allergy_special_instructions_prompt: res.data.inject_allergy_special_instructions_prompt,
        toastPromos: res.data.toast_item_level_promos,
        toastCheckLevelPromos: res.data.toast_check_level_promos,
      });
    } catch (error) {
      generalErrorAlert(error, "Error connecting Toast.");
    } finally {
      this.setState({ loadingData: false, hasLoaded: true });
    }
  };

  disconnectToast = async () => {
    const { selectedCustomer, onSave } = this.props;
    const { deleteMenuItems } = this.state;

    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        delete_menu_items: deleteMenuItems,
      };
      await axios.post("/admin/disconnectToast", payload);
      toast.success("Successfully disconnected form Toast.");
      this.setState({ selectedRestaurant: null });
      await this.getToastOptions();
      await this.hideModal();
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Error disconnecting Toast.");
    }
  };

  connectRestaurant = async (restaurant) => {
    const { selectedCustomer, onSave } = this.props;
    this.setState({ savingData: true });
    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        restaurantGuid: restaurant.restaurantGuid,
        inject_orders_into_toast: true,
      };
      await axios.post("/admin/connectToast", payload);
      await this.getToastOptions();
      this.setState({ savingData: false });
      toast.success("Successfully connected to " + restaurant.restaurantName);
      this.setState({
        selectedRestaurant: restaurant,
      });
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Error connecting Toast restaurant.");
      this.setState({ savingData: false });
    }
  };

  disconnectOptions = () => (
    <div>
      <div className={"margin-bottom-1"}>
        <p>
          Disconnecting will stop orders from being sent to Toast. If you would also like to delete menu items that came
          from Toast, please check the checkbox below.
        </p>
        <p>If you are not an admin, please contact an admin if you wish to disconnect.</p>
      </div>
      <Form.Group className={"margin-bottom-1 "}>
        <Form.Check
          className={"check-box"}
          label={"Delete existing Toast menu items"}
          type={"checkbox"}
          onChange={(event) => {
            this.setState({ deleteMenuItems: event.target.checked });
          }}
        />
      </Form.Group>
    </div>
  );

  advancedSettings = (values) => {
    const { employees, revenueCenters, ticketFormats, toastPromos, toastCheckLevelPromos } = this.state;
    const enforeSanity = (e) => {
      if (!e) {
        values.sync_fulfillment_status_batched = false;
      }
    };
    return (
      <div>
        <div className={"margin-bottom-2"}>
          <div className={"margin-bottom-1 padding-x-1"}>
            To find more about configuring Toast, check out{" "}
            <a href={"https://central.bbot.menu/article/449-configuring-toast-integration"}>this article</a>.
          </div>
          <CheckboxInput
            sm={12}
            label={"Inject orders into Toast?"}
            name={"inject_orders_into_toast"}
            id={"inject-orders"}
          />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>
              Bbot orders will be put into Toast. If orders don't appear the way you expect, follow the "Verify Orders
              Work" steps in the{" "}
              <a href="https://central.bbot.menu/article/449-configuring-toast-integration" style={{ fontSize: 14 }}>
                Bbot Toast setup guide.
              </a>
            </small>
          </div>
          <CheckboxInput
            sm={12}
            label={"Inject guest names into Toast?"}
            name={"inject_guest_names"}
            id={"inject-guest-names"}
          />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>Bbot will display guest names as special instructions on order items for group orders.</small>
          </div>
          <CheckboxInput
            sm={12}
            label={"Inject allergy related special instructions prompt into Toast?"}
            name={"inject_allergy_special_instructions_prompt"}
            id={"inject-allergy-special-instructions-prompt"}
          />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>
              If a special instructions prompt including the word "allergy" or "allergies" is configured for any menu
              item and this box is checked, Bbot will inject the prompt as a prefix into Toast. For example, if "peanut"
              is entered into the special instruction prompt and "Allergies:" is the prompt, "Allergies: peanut" will be
              injected. The special instructions for all other menu items will inject without their prompt.
            </small>
          </div>
          <CheckboxInput
            sm={12}
            label={'Set Bbot order status to "done" when marked done in Toast'}
            name="sync_fulfillment_status"
            id="sync-fulfillment-status"
            onChange={enforeSanity}
          />
          <div className="margin-bottom-2 margin-left-2 margin-right-2">
            <small>
              When Toast items are marked done on a Toast KDS (e.g. by double-tapping the ticket), they'll be marked
              done in Bbot within a few minutes. For example, pickup orders will be marked "Ready for pickup". This can
              text the guest, just like setting the status in Bbot would.
            </small>
          </div>
          <CheckboxInput
            sm={12}
            label='Set Bbot order status to "done" when ALL items are marked done in Toast'
            name="sync_fulfillment_status_batched"
            id="sync-fulfillment-status-batched"
            disabled={!values.sync_fulfillment_status}
          />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>Mark items done in Bbot only when all Toast items are marked done.</small>
          </div>
          <CheckboxInput
            sm={12}
            label={"Show full delivery info below items on Toast KDS screen?"}
            name={"show_delivery_instruction"}
            id={"show-delivery-instructions"}
          />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>
              If yes, all the delivery info that you require at checkout (e.g. phone, room number, etc.) will be added
              as item "special instructions" so that it's visible on the Toast KDS. (It is always visible on the Bbot
              Terminal, regardless.)
            </small>
          </div>
          <CheckboxInput
            sm={12}
            label={"Automatically mark orders Accepted by Staff in Bbot, once they're injected into Toast?"}
            name={"auto_accept_orders_in_bbot"}
            id={"auto-accept-orders-in-bbot"}
          />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>
              Bbot orders injected into Toast will be set to 'Accepted by Staff' in Bbot for all applicable Fulfillment
              Methods (which excludes Catering and no-feedback methods)
            </small>
          </div>
          <CheckboxInput
            sm={12}
            label={"Add user desired time to item level instructions?"}
            name={"showDesiredTimeOnLineItems"}
            id={"show-desired-time-on-line-items"}
          />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>
              Would you like the user desired time of the order to be printed in the special instructions of each line
              item in the order? This is a legacy feature as the user desired time now prints at the top of the ticket.
            </small>
          </div>
          <CheckboxInput label={"Enable Cloud Tabs"} name={"enableCloudTabs"} id={"enableCloudTabs"} />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>
              If enabled, tabs will inject into Toast as open checks that can be added to by the guest or server.
            </small>
          </div>
          <CheckboxInput label={"Enable Non Bbot Surcharge"} name={"enableNonBbotSurcharge"} id={"enableNonBbotSurcharge"} />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>
              If enabled, Bbot will allow connection to Toast service charges that do not begin with “Bbot"
            </small>
          </div>
          <CheckboxInput label={"Enable Host Reclaim Link"} name={"enableReclaimHostLink"} id={"enableReclaimHostLink"} />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>
              If enabled, group tab host will receive link to reclaim tab.
            </small>
          </div>
          <CheckboxInput label={"Enable Server Initiated Tabs"} name={"enableServerInitiatedTabs"} id={"enableServerInitiatedTabs"} />
          <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
            <small>
              If enabled, tabs created by Server can be closed/updated on Bbot.
            </small>
          </div>
        </div>

        <div className={"margin-bottom-4"}>
          <SelectInput name={"ticketFormat"} id={"ticket-format"} label={"Toast Ticket Format"}>
            {ticketFormats.map((ticketFormat, i) => (
              <option value={ticketFormat.name} key={i}>
                {ticketFormat.prettyString}
              </option>
            ))}
          </SelectInput>
        </div>

        <div className={"margin-bottom-4"}>
          <SelectInput name={"revenueCenterGuid"} id={"revenue-center-guid"} label={"Bbot Order Revenue Center"}>
            <option key={"default"}>Please select a revenue center.</option>
            {revenueCenters.map((revenueCenter, i) => (
              <option key={i} value={revenueCenter.guid}>
                {revenueCenter.name}
              </option>
            ))}
          </SelectInput>
        </div>

        <div className={"margin-bottom-4"}>
          <SelectInput name={"employeeGuid"} id={"employee-guid"} label={"Bbot Order Employee"}>
            {employees.map((employee, i) => (
              <option key={i} value={employee.guid}>
                {employee.firstName && employee.lastName
                  ? `${employee.firstName} ${employee.lastName}`
                  : employee.email}
              </option>
            ))}
          </SelectInput>
        </div>
        {values.inject_orders_into_toast && (
          <div className={"margin-bottom-4"}>
            <TemplateStringInputField
              label={"Tab Name Template String"}
              id={"tab-name-template-string"}
              name={"tabNameTemplateString"}
              subText={
                "Use custom formatting for the tab name that is injected into Toast.  " +
                "This will overwrite your ticket format and will print the tab name at the top of printed tickets.  " +
                "Max characters for this field is 255.  If the formatted string is longer than that it will be shortened."
              }
            />
          </div>
        )}
        <div className={"margin-bottom-2"}>
          <CheckboxInput
            label={"Respect Toast Visibility Rules"}
            name={"obeyToastVisibility"}
            id={"obeyToastVisibility"}
          />
        </div>
        <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
          <small>
            If checked, Bbot will only sync menu items that are marked "Visible to Online orders: Ordering partners" in
            Toast.
          </small>
        </div>
        <div className={"margin-bottom-2"}>
          <CheckboxInput
            label={"Sync Inventory Quantities"}
            name={"syncInventoryQuantities"}
            id={"syncInventoryQuantities"}
          />
        </div>
        <div className={"margin-bottom-4"}>
          <SelectInput name={"bogoPromo"} id={"bogo-promo"} label={"BOGO Promo"}>
            <option key={"default"}>Please select a Promo to use for BOGO offers.</option>
            {toastPromos.map((promo, i) => (
              <option key={i} value={promo.external_id}>
                {promo.name}
              </option>
            ))}
          </SelectInput>
        </div>
        <div className="margin-bottom-4">
            <SelectInput name={"checkLevelPromo"} id={"check-level-promo"} label={"Check Level Promo"}>
              <option key={"default"}>Please select a Promo for customers to use for loyalty attachment</option>
              {toastCheckLevelPromos.map((promo, i) => (
                <option key={i} value={promo.external_id}>
                  {promo.name}
                </option>
              ))}
            </SelectInput>
        </div>
        
      </div>
    );
  };

  saveSettings = async (values) => {
    const { selectedCustomer } = this.props;
    const { selectedRestaurant } = this.state;

    this.setState({ savingData: true });

    try {
      const payload = {
        restaurantGuid: selectedRestaurant.restaurantGuid,
        employeeGuid: values.employeeGuid,
        revenueCenterGuid: values.revenueCenterGuid,
        ticketFormat: values.ticketFormat,
        inject_orders_into_toast: values.inject_orders_into_toast,
        sync_fulfillment_status: values.sync_fulfillment_status,
        sync_fulfillment_status_batched: values.sync_fulfillment_status_batched,
        show_delivery_instruction: values.show_delivery_instruction,
        auto_accept_orders_in_bbot: values.auto_accept_orders_in_bbot,
        customer_id: selectedCustomer.customer_id,
        show_desired_time_on_line_items: values.showDesiredTimeOnLineItems,
        tab_name_template_string: values.tabNameTemplateString,
        obey_toast_visibility: values.obeyToastVisibility,
        sync_inventory_quantities: values.syncInventoryQuantities,
        enable_cloud_tabs: values.enableCloudTabs,
        enable_server_initiated_tabs: values.enableServerInitiatedTabs,
        enable_non_bbot_surcharge: values.enableNonBbotSurcharge,
        enable_reclaim_host_link: values.enableReclaimHostLink,
        inject_guest_names: values.inject_guest_names,
        inject_allergy_special_instructions_prompt: values.inject_allergy_special_instructions_prompt,
        bogo_promo: values.bogoPromo,
        check_level_promo: values.checkLevelPromo,
      };
      await axios.post("/admin/connectToast", payload);
      this.setState({ savingData: false });
      toast.success("Successfully saved Toast integration settings.");
      await this.hideModal();
      await this.getToastOptions();
    } catch (error) {
      this.setState({ savingData: false });
      console.error(error);
    }
  };

  disconnectModal = () => {
    const { show, userIsAdmin } = this.props;
    return (
      <Modal show={show} onHide={this.hideModal} className={"toast-modal"}>
        <Modal.Header closeButton>
          <Modal.Title>Disconnect From Toast</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.disconnectOptions()}</Modal.Body>
        <Modal.Footer className={"space-between"}>
          <Button size={"sm"} variant={"light"} onClick={this.hideModal} className={"margin-right-1"}>
            Cancel
          </Button>
          <Button
            size={"sm"}
            variant={"danger"}
            onClick={this.disconnectToast}
            disabled={!userIsAdmin}
            data-test-id={"disconnect-toast-modal-button"}
          >
            Disconnect
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const { show, disconnectMode, userIsAdmin } = this.props;
    const {
      toastSettings,
      restaurants,
      selectedRestaurant,
      ticketFormat,
      selectedEmployeeGuid,
      selectedRevenueCenterGuid,
      loadingData,
      savingData,
    } = this.state;

    const integrationTitleMessage = userIsAdmin ? "Integrate with Toast" : "Not an Admin?";

    return disconnectMode ? (
      this.disconnectModal()
    ) : (
      <Modal show={show} onHide={this.hideModal} className={"toast-modal"} size={"lg"}>
        <Formik
          enableReinitialize
          initialValues={{
            inject_orders_into_toast: toastSettings.inject_orders_into_toast,
            sync_fulfillment_status: toastSettings.sync_fulfillment_status,
            sync_fulfillment_status_batched: toastSettings.sync_fulfillment_status_batched,
            show_delivery_instruction: toastSettings.show_delivery_instruction,
            auto_accept_orders_in_bbot: toastSettings.auto_accept_orders_in_bbot,
            ticketFormat: ticketFormat || "name_location",
            employeeGuid: selectedEmployeeGuid || "",
            revenueCenterGuid: selectedRevenueCenterGuid || "",
            bogoPromo: toastSettings.bogo_promo_id,
            checkLevelPromo: toastSettings.check_level_promo_id,
            showDesiredTimeOnLineItems: toastSettings.show_desired_time_on_line_items ?? true,
            tabNameTemplateString: toastSettings.tab_name_template_string ?? "",
            obeyToastVisibility: toastSettings.obey_toast_visibility ?? true,
            syncInventoryQuantities: toastSettings.sync_inventory_quantities ?? false,
            enableCloudTabs: toastSettings.enable_cloud_tabs ?? false,
            enableServerInitiatedTabs: toastSettings.enable_server_initiated_tabs ?? false,
            enableNonBbotSurcharge: toastSettings.enable_non_bbot_surcharge ?? false,
            enableReclaimHostLink: toastSettings.enable_reclaim_host_link ?? false,
            inject_guest_names: toastSettings.inject_guest_names ?? false,
            inject_allergy_special_instructions_prompt:
              toastSettings.inject_allergy_special_instructions_prompt ?? false,
          }}
          onSubmit={this.saveSettings}
        >
          {({ values }) => (
            <FormikForm className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {selectedRestaurant
                    ? "Connected To " +
                      selectedRestaurant.restaurantName +
                      " (Location: " +
                      selectedRestaurant.locationName +
                      ")"
                    : integrationTitleMessage}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className={"no-overflow"}>
                {loadingData || savingData ? (
                  <CenteredLoadingSpinner
                    label={loadingData ? "Loading Toast Integration data" : "Integrating With Toast"}
                  />
                ) : (
                  <>
                    {selectedRestaurant && this.advancedSettings(values)}
                    {!selectedRestaurant && userIsAdmin && (
                      <div>
                        <div className={"margin-bottom-2"}>
                          <p>
                            Please select a restaurant to connect with. This will import menus and start sending orders
                            over.
                          </p>
                        </div>
                        <div className={"padding-x-2 customer-chooser"} data-test-id={"toast-select-restaurant"}>
                          <ReactSelect
                            options={restaurants.map((restaurant) => ({
                              value: restaurant,
                              label:
                                restaurant.restaurantName +
                                (restaurant.locationName ? " - " + restaurant.locationName : "") +
                                (restaurant.connected_bbot_customer_name
                                  ? " - (Currently linked to: " + restaurant.connected_bbot_customer_name + ")"
                                  : ""),
                            }))}
                            onChange={(option) => {
                              if (!savingData) {
                                this.connectRestaurant(option.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {!selectedRestaurant && !userIsAdmin && (
                      <div>
                        <div className={"margin-bottom-2"}>
                          <p>Not an Admin? Contact an admin to set up a Toast Integration.</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                {selectedRestaurant ? (
                  <>
                    <span>
                      <Button
                        size={"sm"}
                        variant={"light"}
                        onClick={this.hideModal}
                        className={"margin-right-1"}
                        data-test-id={"cancel-toast-modal-button"}
                      >
                        Cancel
                      </Button>
                      <Button
                        size={"sm"}
                        variant={"primary"}
                        type={"submit"}
                        disabled={savingData}
                        data-test-id={"save-toast-settings"}
                      >
                        Save
                      </Button>
                    </span>
                  </>
                ) : (
                  <Button size={"sm"} variant={"light"} onClick={this.hideModal}>
                    Cancel
                  </Button>
                )}
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </Modal>
    );
  }
}

ToastIntegrationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
  disconnectMode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ToastIntegrationModal;
